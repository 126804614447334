<template>
    <div>
        <portal to="layout-default-header">
            <header-default :title="user.name" />
        </portal>

        <b-row align-h="center">
            <b-col
                md="8"
                lg="6"
                class="card-stack"
            >
                <b-card>
                    <user-form
                        :user="user"
                        :editable="false"
                    />
                </b-card>

                <b-card v-if="company">
                    <company-form
                        :company="company"
                        :editable="false"
                    />
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import HeaderDefault from '@/components/layouts/HeaderDefault';
import {User} from '@/models/User';
import CompanyForm from '@/components/companies/Form';
import UserForm from '@/components/users/Form';

export default {
    name: 'UserShow',
    components: {CompanyForm, UserForm, HeaderDefault},
    props: {
        user: {
            type: User,
            required: true,
        },
    },
    data: function() {
        return {
            company: this.user.company
                ? this.user.company.clone()
                : null,
        };
    },
    watch: {
        async user(user) {
            if (user.company) {
                this.company = user.company.clone();
            }
        },
    },
};
</script>
