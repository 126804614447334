<template>
    <div class="overflow-hidden">
        <svg-circle class="stroke-light position-absolute top-right" />

        <h1
            v-if="title"
            class="text-capitalize"
        >
            {{ title }}
        </h1>
    </div>
</template>

<script>
import SvgCircle from '@/components/common/SvgCircle';

export default {
    name: 'HeaderDefault',
    components: {SvgCircle},
    props: {
        title: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
.top-right {
    right: 0;
    bottom: 0;
    transform: translate(75%, -5%);
}
</style>
