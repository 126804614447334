<template>
    <b-form @submit.prevent="save()">
        <input
            type="submit"
            class="d-none"
        >

        <h4 class="mb-4">
            {{ $t('users.edit.nav') }}
        </h4>

        <b-row
            align-v="center"
            class="mb-4"
        >
            <b-col class="d-flex align-items-center">
                <b-form-group
                    class="mb-0"
                    :invalid-feedback="user.errors.image"
                    :state="!user.errors.image"
                    :disabled="!editable"
                >
                    <ow-image-select v-model="user.image" />
                </b-form-group>

                <label class="col-form-label ml-4">
                    {{ $t('users.model.image.label') }}
                </label>
            </b-col>

            <b-col>
                <b-form-group
                    class="mb-0"
                    :label="$t('users.model.name.label')"
                    :invalid-feedback="user.errors.name"
                    :state="!user.errors.name"
                    :disabled="!editable"
                >
                    <b-input v-model="user.name" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    :label="$t('users.model.phone.label')"
                    :invalid-feedback="user.errors.phone"
                    :state="!user.errors.phone"
                    :disabled="!editable"
                >
                    <b-input v-model="user.phone" />
                </b-form-group>
            </b-col>

            <b-col>
                <b-form-group
                    :label="$t('users.model.email.label')"
                    :invalid-feedback="user.errors.email"
                    :state="!user.errors.email"
                    :disabled="!editable"
                >
                    <b-input v-model="user.email" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-form-group
            :label="$t('users.model.bio.label')"
            :invalid-feedback="user.errors.bio"
            :state="!user.errors.bio"
            :disabled="!editable"
        >
            <b-textarea
                v-model="user.bio"
                :placeholder="bioPlaceholder"
                rows="5"
            />
        </b-form-group>

        <!-- Change Password -->
        <template v-if="editable">
            <b-link
                v-b-modal="'modal-change-password'"
                class="text-underline"
            >
                {{ $t('users.actions.changePassword') }}
            </b-link>

            <modal-change-password
                id="modal-change-password"
                :user="user"
            />
        </template>
    </b-form>
</template>

<script>
import {User} from '@/models/User';
import OwImageSelect from '@/components/common/form/OwImageSelect';
import ModalChangePassword from '@/components/users/ModalChangePassword';

export default {
    name: 'UserForm',
    components: {OwImageSelect, ModalChangePassword},
    props: {
        user: {
            type: User,
            required: true,
        },
        editable: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        bioPlaceholder() {
            return this.user.id === this.$me.id
                ? this.user.getTranslation('bio', 'placeholder')
                : this.user.getTranslation('bio', 'placeholderNotMe');
        },
    },
    methods: {
        async save() {
            if (!this.editable) {
                return;
            }

            await this.user.save();
        },
    },
};
</script>
