<template>
    <b-modal
        ref="modal"
        v-bind="$attrs"
        size="sm"
        centered
        :title="$t('users.actions.changePassword')"
    >
        <template #default>
            <b-form @submit.prevent="changePassword()">
                <input
                    type="submit"
                    class="d-none"
                >

                <b-form-group
                    :label="$t('users.model.currentPassword.label')"
                    :invalid-feedback="dUser.errors.currentPassword"
                    :state="!dUser.errors.currentPassword"
                >
                    <b-input
                        v-model="dUser.currentPassword"
                        type="password"
                    />
                </b-form-group>

                <b-form-group
                    :label="$t('users.model.newPassword.label')"
                    :invalid-feedback="dUser.errors.password"
                    :state="!dUser.errors.password"
                >
                    <b-input
                        v-model="dUser.password"
                        type="password"
                    />
                </b-form-group>
            </b-form>
        </template>

        <template #modal-footer="{cancel}">
            <b-btn
                class="btn-min-width"
                variant="light"
                @click="cancel()"
            >
                {{ $t('buttons.cancel') }}
            </b-btn>

            <btn-resource
                variant="dark"
                class="btn-min-width"
                :resource="dUser"
                @click="changePassword()"
            >
                {{ $t('users.actions.changePassword') }}
            </btn-resource>
        </template>
    </b-modal>
</template>

<script>
import {User} from '@/models/User';
import {detainFeedback} from '@/library/helpers';

export default {
    name: 'UserModalChangePassword',
    props: {
        user: {
            type: User,
            required: true,
        },
    },
    data() {
        return {
            dUser: null,
        };
    },
    created() {
        // Use local User model instance, so the parent's user instance is not mutated.
        this.dUser = this.user.clone();
    },
    methods: {
        async changePassword() {
            const data = {
                password: this.dUser.password,
                currentPassword: this.dUser.currentPassword,
            };

            await this.dUser.save({data});

            detainFeedback(() => {
                this.$refs['modal'].hide();

                // Clear the passwords.
                this.dUser.unset(['password', 'currentPassword']);
                this.dUser.sync();
            });
        },
    },
};
</script>
